@import "modules/responsive-type.scss";

$primary: #a41c52;/* MAIN COLOR */
$secondary: #333333; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Abel|Alfa+Slab+One');

// font-family: 'Abel', sans-serif;
// font-family: 'Alfa Slab One', cursive;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
   font-family: 'Abel', sans-serif;
    font-size: 1rem;
}

h1 {
    font-family: 'Alfa Slab One', cursive;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 { 
    font-family: 'Alfa Slab One', cursive;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
    font-family: 'Alfa Slab One', cursive;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #000;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background-color: #cacaca;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.20);
    .navbar-nav {
        >li>a {
            font-family: 'Alfa Slab One', cursive;
            text-align: center;
            margin-top: 35px;
            display: flex;
            align-items: center;
            color: $blk;
            transition-duration: 0.5s;
            border-radius: $border-radius;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 25px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $blk;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
            -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 225px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 180px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 130px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    padding: 1em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    font-family: 'Abel', sans-serif;
        font-size: 1rem;
        li {
            font-size: 1rem;
        }
    
    & ol {
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.shadowthis {
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
}
.shadowthat{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
.padTop{
    padding-top: 25px;
}
.padBot{
    padding-bottom: 25px;
}
.marTop{
    margin-top: 25px;
}
.marBot{
    margin-bottom: 25px;
}

#circle {
    width: 300px;
    height: 300px;
    -moz-border-radius: 150px;
    -webkit-border-radius: 150px;
    border-radius: 150px;
    
    @media (max-width: 1290px) {
        width: 275px;
        height: 275px;
        -moz-border-radius: 175px;
        -webkit-border-radius: 175px;
        border-radius: 175px;
    }


    @media (max-width: 991px) {
        width: 225px;
        height: 225px;
        -moz-border-radius: 125px;
        -webkit-border-radius: 125px;
        border-radius: 125px;
    }
}


#signUpForm {
    // background: #fff !important;
    padding: 0px 10px !important;
    
    @media (max-width:991px) {
         background: #fff !important;
    }
}

.intro{
    // background: url(../img/banner.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 350px 0px;

    @media (max-width: 991px) {
       padding: 300px 0px; 
    }

    @media (max-width: 768px) {
       padding: 250px 0px; 
    }

    @media (max-width: 568px) {
       padding: 150px 0px; 
    }
    
    @media (max-width: 320px) {
       padding: 100px 0px; 
    }

    h1{
        color: #fff;
    }
}

.divider{
    background: $primary;
    padding: 150px 0px;

    @media (max-width: 991px) {
       padding: 140px 0px; 
    }

    @media (max-width: 768px) {
       padding: 125px 0px; 
    }

    @media (max-width: 568px) {
       padding: 100px 0px; 
    }
    
    @media (max-width: 320px) {
       padding: 50px 0px; 
    }


    h1, p{
        color: #fff;
    
    @media (max-width: 991px) {
       text-align: center; 
        }
    }
}


.form{
    margin-top: 25px;

    @media (max-width: 991px) {
        margin: 0px 0px;
        background: #333;
    }

    .col-lg-3{
        @media (max-width: 991px) {
        padding-left: 0px;
        padding-right: 0px;
        }
    }

    .changebg{

        padding-bottom: 10px;

        @media(max-width: 991px){
            background: #333; 
            padding-top: 25px;
        }
    }

    .img1 {
        background: url(../img/img1.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid #fff;
    }

    .img2 {
        background: url(../img/img2.jpg);
        height: 300px;
        background-repeat: no-repeat;

        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid #fff;
        margin-bottom: 25px;
    }


    .img3 {
        background: url(../img/img3.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid #fff;
        // margin-bottom: 25px;
    }

    .img4 {
        background: url(../img/img4.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid #fff;
        margin-bottom: 25px;
    }


    .hideme{
        display: block;
        @media (max-width: 991px) {
            display: none;
        }
    }

    .showme{

        display: none;

        @media (max-width: 991px) {
            display: block;
        }

    }


    h1{
        color: rgba(255, 255, 255, 0.38);
        padding-top: 90px;

        @media (max-width: 991px) {
            padding-top: 70px;
        }

        @media (max-width: 991px) {
            padding-top: 75px;
        }
    }
}


.outro{
    // background: url(../img/banner2.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/banner2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 350px 0px;

    @media (max-width: 991px) {
       padding: 300px 0px; 
    }

    @media (max-width: 768px) {
       padding: 250px 0px; 
    }

    @media (max-width: 568px) {
       padding: 150px 0px; 
    }
    
    @media (max-width: 320px) {
       padding: 100px 0px; 
    }

    h1{
        color: #fff;
    }

}

.btn {
  // font-family: 'Quicksand', sans-serif;
  font-family: 'Alfa Slab One', cursive;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;
  transition-duration: 0.5s;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}

@media (max-width: 896px) and (orientation: landscape){
  .modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 0em auto;
  }
}